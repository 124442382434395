import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message is-danger"
}
const _hoisted_2 = { class: "message-body" }
const _hoisted_3 = { class: "field is-horizontal" }
const _hoisted_4 = { class: "field-label is-normal" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "field-body" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errors.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errors.message), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('category.NAME')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(["input", { 'is-danger': _ctx.errors.name }]),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event))
            }, null, 2), [
              [_vModelText, _ctx.model.name]
            ])
          ]),
          (_ctx.errors.name)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.errors.name), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}