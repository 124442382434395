
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
    props: ['form'],

    setup(props) {
        const { model, errors } = toRefs(props.form.state);

        return {
            model,
            errors
        };
    },
});
